// import Bugsnag from '@bugsnag/js'

// Bugsnag.start({
//   apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
//   enabledReleaseStages: ["production", "staging"],
//   releaseStage: process.env.NODE_ENV,
// });
import '/assets/css/app.css';
import $ from 'jquery';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import '/assets/js/counter'; 

import '/assets/js/owl-accordion.js';
import '/assets/js/owl-case-studies.js';
import '/assets/js/modal.js';
// import '/assets/js/gliderjs'; //custom script
export const isMobile = {
  android: () => navigator.userAgent.match(/Android/i),
  blackberry: () => navigator.userAgent.match(/BlackBerry/i),
  ios: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  opera: () => navigator.userAgent.match(/Opera Mini/i),
  windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => (isMobile.android() || isMobile.blackberry() || 
  isMobile.ios() || isMobile.opera() || isMobile.windows())
};

// add touch device class
isMobile.any() ? document.body.classList.add('is-touch') : null;



if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("loadedHMR")
  });
}
var last_known_scroll_position = 0;
var ticking = false;
let header = document.querySelector('#global-header .container');

function doSomething(scroll_pos) {
  if (scroll_pos > 135) {
    // header.classList.add('moved');
  } else {
    // header.classList.remove('moved')
  }
}



window.addEventListener('scroll', function (e) {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {

    window.requestAnimationFrame(function () {
      doSomething(last_known_scroll_position);
      ticking = false;
    });

    ticking = true;

  }

});

// does this really need a carousel?
//look a css options
$(".owl-carousel-case-studies").owlCarousel({
  items: 1,
  nav: false,
  responsive:{
    0:{
        items:2,
        dots: false,
    },
    640:{
        items:3,
        dots: true,
        dotsEach: true,
    },
    1200:{
      items:5,
      dots: true,
      dotsEach: true,
  }
}
});

$(".owl-carousel-accrediations").owlCarousel({
  loop:true,
  margin: 30,
  responsiveClass:true,
  autoplay: true,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  nav: false,
  responsive:{
    0:{
        items:2,
        dots: false,
    },
    640:{
        items:3,
        dots: true,
        dotsEach: true,
    },
    1200:{
      items:5,
      dots: true,
      dotsEach: true,
  }
}
});


$(".owl-carousel-projects").owlCarousel({
  itemElement: 'li',
  stageElement: 'ul',
  loop:false,
  responsiveClass:true,
  autoWidth: true,
  autoplay: false,
  center: true,
  autoplayHoverPause: false,
  nav: true,
  dots: false,
  responsive:{
    0:{
        items:2,
        dots: false,
    },
    640:{
        items:10,
        dots: true,
        dotsEach: true,
    },
    1200:{
      items:10,
      dots: true,
      dotsEach: true,
  }
}
});

window.mailchimpSubmit = function (event) {
  console.log(event);
  event.preventDefault();
  let theForm = $(event.target);
  let data = theForm.serialize();
  theForm.find('input[type=submit]').hide();

  $.ajaxSetup({
    headers: {
      "Accept": "application/json"
    }
  });
  $.post('/', data)
    .done((response) => {
      console.log(response);
      if (response.error) {
        if (response.error === "missing-input-response") {
          alert("Please tell us you aren't a robot.");
          theForm.find('input[type=submit]').show();
        } else {
          alert("Sorry, there's been an issue with this form. Please try again later or contact us.")
        }
      } else {
        if (response.endpoint) {
          theForm.attr('onsubmit', '');
          theForm.attr('action', response.endpoint);
          theForm.attr('target', '_blank');
          theForm.submit(); // todo - handle this differently, runs into the JS window.open issue.
        }
      }
    })
};

// map accordion
var acc = document.getElementsByClassName("accordion");

for (let i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    var active = document.querySelector(".accordion.active");
    if (active && active != this) {
      active.classList.remove("active");
      active.nextElementSibling.classList.remove("show");
    }
    this.classList.toggle("active");
    let j = "0";
    for (j = 0; j < acc.length; j++) {
      if (j !== i)
        acc[j].nextElementSibling.style.maxHeight = null;
    }
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}
// offcanvas menu listen to add no scroll class to body page and allow menu to scroll
$('#toogle-menu').click(function(e) {
  // e.preventDefault();
  // if($('body').hasClass('noscroll')){

  // }
  $('body').toggleClass('noscroll');
  // console.log('menu');
});

// remove menu click on hover over parent on mobile
// $(function(){
$('.is-touch li.children a:nth(0)').click(function(e) {
$(this).attr('href', '#');
var $El = $('.is-touch #global-header .offcanvas-menu > nav > ul > li.children');

$El.addClass('close');
if($El.hasClass('open')){
  $El.addClass('close');
  $El.removeClass('open')
}else{
  if($El.hasClass('close')){
    $El.addClass('open');
    $El.removeClass('close')  
  }
}
e.stopPropagation();
  
});
// });
// Accordian JQuery

$('.toggle').click(function(e) {
  e.preventDefault();

  var $this = $(this);

  if ($this.hasClass('open')) {
    $this.removeClass('open');
  } else {
    $this.toggleClass('open');
  }

  if ($this.next().hasClass('show')) {
      $this.next().removeClass('show');
      $this.next().slideUp(350);
  } else {
      $this.parent().parent().find('li .inner').removeClass('show');
      $this.parent().parent().find('li .inner').slideUp(350);
      $this.next().toggleClass('show');
      $this.next().slideToggle(350);
  }
});

// video button


// console.log(playPauseButton)



document.addEventListener('DOMContentLoaded', function() {



  // console.log('document is ready. I can sleep now');
  const videoElement = document.getElementById('video');
const playPauseButton = document.getElementById('btn-play');

 
// if (playPauseButton != null) {
// playPauseButton.addEventListener('click', () => {
// 	playPauseButton.classList.toggle('playing');
// 	if (playPauseButton.classList.contains('playing')) {
// 		videoElement.play();
// 	}
// 	else {
// 		videoElement.pause();
// 	}
// })
// };

// if (videoElement != null) {
// videoElement.addEventListener('ended', () => {
// 	playPauseButton.classList.remove('playing');
// })};


});

// owl accordion carousel
if ($(window).width() > 1200) {
  function itemSize() {
    var OwlSlideItem = $(".carousel-accordion .accordion_li"),
      // OwlSlideItemmargin = 10,
      itemsLength = 11,
      owlFullScrnWidth = $(".carousel-accordion").width(),
      normItemWidth = owlFullScrnWidth / itemsLength - 9;

    OwlSlideItem.stop().animate({ width: normItemWidth + "px" }, 500);
  }
  itemSize();
}

function itemExpanded() {
  var OwlSlidemactive = $(".carousel-accordion .owl-item.active"),
    // OwlSlideItemmargin = $('.carousel-accordion .owl-item').css('marginRight').replace(/[A-Za-z]/g, ""),
    itemsLength = 11,
    owlFullScrnWidth = $(".carousel-accordion").width() - itemsLength,
    normItemWidth = owlFullScrnWidth / itemsLength - 10,
    lgItemWidth = normItemWidth * 2 + 20,
    smItemWidth = (normItemWidth * 3) / 4 - 3;

  OwlSlidemactive.hover(
    function () {
      var $this = $(this);
      $this
        .addClass("expanded")
        .removeClass("active")
        .find(".accordion_li")
        .stop()
        .animate({ width: lgItemWidth + "px" }, 500);
      $(".carousel-accordion .active")
        .find(".accordion_li")
        .stop()
        .animate({ width: smItemWidth + "px" }, 500);
    },
    function () {
      var $this = $(this);
      $this.removeClass("expanded").addClass("active");
      $(".carousel-accordion .active")
        .find(".accordion_li")
        .stop()
        .animate({ width: normItemWidth + "px" }, 500);
    }
  );
}
initialize_owl($(".carousel-accordion"));

function initialize_owl(el) {
  el.owlCarousel({
    loop: false,
    margin: 10,
    navText: ["<i class='angle-left'></i>", "<i class='angle-right'></i>"],
    dots: false,
    autoPlay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 11
      },
      460: {
        items: 11
      },
      768: {
        items: 11
      },
      900: {
        items: 11
      },
      1200: {
        margin: 0,
        onInitialized: itemExpanded,
        onRefresh: itemExpanded,
        autoWidth: true,
        mouseDrag: false,
        items: 5
      }
    }
  });
}
