// totally stolen
if ($(window).width() > 760) {
    function itemSize() {
      var OwlSlideItem = $(".carousel-accordion .accordion_li"),
        // OwlSlideItemmargin = 10,
        itemsLength = 5,
        owlFullScrnWidth = $(".carousel-accordion").width(),
        normItemWidth = owlFullScrnWidth / itemsLength - 9;
  
      OwlSlideItem.stop().animate({ width: normItemWidth + "px" }, 500);
    }
    itemSize();
  }
  
  function itemExpanded() {
    var OwlSlidemactive = $(".carousel-accordion .owl-item.active"),
      // OwlSlideItemmargin = $('.carousel-accordion .owl-item').css('marginRight').replace(/[A-Za-z]/g, ""),
      itemsLength = 5,
      owlFullScrnWidth = $(".carousel-accordion").width() - itemsLength,
      normItemWidth = owlFullScrnWidth / itemsLength - 10,
      lgItemWidth = normItemWidth * 2 + 20,
      smItemWidth = (normItemWidth * 3) / 4 - 3;
  
    OwlSlidemactive.hover(
      function () {
        var $this = $(this);
        $this
          .addClass("expanded")
          .removeClass("active")
          .find(".accordion_li")
          .stop();
         // .animate({ width: lgItemWidth + "px" }, 500);
        $(".carousel-accordion .active")
          .find(".accordion_li")
          .stop()
          //.animate({ width: smItemWidth + "px" }, 500);
          .animate({ width: "106px" }, 500);
      },
      function () {
        var $this = $(this);
        $this
        .removeClass("expanded")
        .addClass("active");
        $(".carousel-accordion .active")
          .find(".accordion_li")
          .stop()
          // // .animate({ width: normItemWidth + "px" }, 500);
          .animate({ width: "106px" }, 500);
      }
    );
  }
  initialize_owl($(".carousel-accordion"));
  

  function initialize_owl(el) {
    el.owlCarousel({
      loop: true,
      margin: 7,
      navText: ["<img src='/assets/images/left.png>", "<img src='/assets/images/right.png>"],
      dots: false,
      autoplay: true,
      // rewind:true,
      autoplayTimeout: 1400,
      autoplayHoverPause: true,
      nav: true,
      responsiveClass: true,
      autoWidth: true,
      responsive: {
        0: {
          items: 3.5
        },
        460: {
          items: 3.5
        },
        768: {
          items: 4.5
        },
        900: {
          items: 7.5
        },
        1200: {
          
          onInitialized: itemExpanded,
          onRefresh: itemExpanded,
          //autoWidth: true,
          //mouseDrag: false,
          items: 8
        },
        1650: {
          loop:false,
          autoplay: false,
          // autoplayHoverPause: false,
        }
      }
    });
  }
  